import { Injectable } from '@angular/core';
import { PDFDocumentProxy, PDFSource, PDFProgressData, PDFPromise } from 'pdfjs-dist';
import { Subject } from 'rxjs';
import { values } from 'lodash-es';

import { PdfDocument } from '../models/pdf-document';

import * as PDFJS from 'pdfjs-dist/build/pdf';

@Injectable()
export class PdfService {

    loadProgress$ = new Subject<PDFProgressData>();
    loadComplete$ = new Subject<PdfDocument>();
    loadError$ = new Subject<any>();

    private cache: { [key: string]: PdfDocument } = {};
    private currentTask: any;

    constructor() {
        PDFJS.verbosity = PDFJS.VerbosityLevel.ERRORS;
        this.setupWorker();
    }

    load(src: string | Uint8Array | PDFSource, cache = false) {

        let url;

        if (typeof src === 'string') {
            url = src;
        } else if (src && 'byteLength' in src) { // ArrayBuffer
        } else if (src['url']) {
            url = src['url'];
        }

        const shouldNotUseCached = src['cached'] === false;

        if (cache && url && !shouldNotUseCached) {
            const cached = this.cache[url];
            if (cached) {
                this.loadComplete$.next(cached);
                return;
            }
        }

        const loadingTask: any = PDFJS.getDocument(src);

        if (this.currentTask) {
            this.currentTask.cancelled = true;
        }

        this.currentTask = loadingTask;

        const promise = loadingTask.promise as PDFPromise<PDFDocumentProxy>;

        loadingTask.onProgress = (progressData: PDFProgressData) => {
            if (loadingTask.cancelled) { return; }
            this.loadProgress$.next(progressData);
        };

        promise
            .then((proxy: PDFDocumentProxy) => {
                this.currentTask = null;
                const pdf = new PdfDocument(proxy, url);
                if (url) {
                    this.cache[url] = pdf;
                }
                if (!loadingTask.cancelled) {
                    this.loadComplete$.next(pdf);
                }
            }, (error: any) => {
                this.currentTask = null;
                this.loadError$.next(error);
            });
    }

    clearCache() {
        values(this.cache).forEach(x => x.destroy());
        this.cache = {};
    }

    private setupWorker() {
        let pdfWorkerSrc: string;

        if (window.hasOwnProperty('pdfWorkerSrc') && typeof (window as any).pdfWorkerSrc === 'string' && (window as any).pdfWorkerSrc) {
            pdfWorkerSrc = (window as any).pdfWorkerSrc;
        } else {
            pdfWorkerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFJS.version}/pdf.worker.min.js`;
        }

        PDFJS.GlobalWorkerOptions.workerSrc = pdfWorkerSrc;
    }
}
